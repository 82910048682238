.layout-single-form {
  @apply container relative pt-6 sm:pt-10 pb-16 lg:pt-20 lg:pb-28;
}
.form-container {
  @apply p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-neutral-900;

  > header {
    @apply text-center max-w-2xl mx-auto mb-14 sm:mb-16 lg:mb-20;

    h2 {
      @apply flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center;
    }

    span {
      @apply block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200;
    }
  }

  > div {
    @apply max-w-md mx-auto space-y-6;

    > form {
      @apply grid grid-cols-1 gap-6;

      .call-out {
        @apply flex justify-between;

        > a {
          @apply text-primary-600 hover:text-primary-800 dark:text-primary-500 dark:hover:text-primary-600 text-sm underline;
        }
      }

      > button[type='submit'] {
        @apply flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-700 hover:bg-primary-600 text-primary-50 text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6;
      }
    }

    .form-actions-divider {
      @apply relative text-center;

      > span {
        @apply relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900;
      }

      > div {
        @apply absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800;
      }
    }

    .social-login {
      @apply grid gap-3;

      .social-login-link {
        @apply flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px];

        > h3 {
          @apply flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm;
        }

        > img {
          @apply flex-shrink-0;
          width: auto;
        }
      }
    }
  }
}
